<template>
  <div class="pay-data">
    <bc-query
      :query-list="queryList"
      :query-param="queryParam"
      @submit="getParam"
      @reset="resetParam">
    </bc-query>
    <bc-table
      :table-header="tableHeader"
      :table-data="tableData"
      :loading="loading">
    </bc-table>
    <bc-pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="loadData" />
  </div>
</template>

<script>
import { orderDay, getAppList } from '@/api/application';
import { formatDate } from '@/assets/js/utils';

const now = formatDate(new Date(), 'Y-M-D');
const aMonthBeforeToday = formatDate(
  new Date(new Date().getTime() - 3600 * 1000 * 24 * 30),
  'Y-M-D'
);

export default {
  name: 'payData',
  data() {
    return {
      queryList: [
        {
          type: 'dateRangeLimit',
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              this.pickerMinDate = maxDate ? '' : minDate.getTime();
            },
            disabledDate: (time) => {
              const eightDay = 90 * 24 * 3600 * 1000;
              const minTime = this.pickerMinDate - eightDay;
              const maxTime = this.pickerMinDate + eightDay;
              if (
                this.pickerMinDate !== '' &&
                this.pickerMinDate !== undefined
              ) {
                return (
                  time.getTime() > maxTime ||
                  time.getTime() < minTime ||
                  time.getTime() > Date.now()
                );
              }
              return time.getTime() > Date.now();
            },
          },
        },
        {
          name: '应用',
          type: 'select',
          opt: [],
        },
      ],
      queryParam: [{ time: [aMonthBeforeToday, now] }, { app_id: '' }],
      loading: false,
      tableHeader: [
        {
          prop: 'date',
          label: '日期',
        },
        {
          prop: 'app_name',
          label: '应用名称',
        },
        {
          prop: 'order_num_pay',
          label: '充值次数（有效订单数）',
        },
        {
          prop: 'user_pay',
          label: '充值人数',
        },
        {
          prop: 'order_amount_pay',
          label: '充值金额',
        },
        {
          prop: 'order_num',
          label: '订单总数',
        },
        {
          prop: 'order_rate',
          label: '订单完成率（%）',
        },
      ],
      tableData: [],
      total: 0,
      listQuery: {
        page: 1,
        limit: 20,
        app_id: '',
        begin_data: aMonthBeforeToday,
        end_date: now,
      },
    };
  },
  methods: {
    resetParam() {
      this.queryParam[0].time = [aMonthBeforeToday, now];
      this.listQuery.begin_data = aMonthBeforeToday;
      this.listQuery.end_date = now;
      this.listQuery.app_id = '';
      this.listQuery.page = 1;
      this.loadData();
    },
    getParam(data) {
      this.listQuery.app_id = data.app_id;
      this.listQuery.begin_data = data.time[0];
      this.listQuery.end_date = data.time[1];
      this.listQuery.page = 1;
      this.loadData();
    },
    loadData() {
      this.loading = true;
      orderDay(this.listQuery).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.pages.total;
        this.loading = false;
      });
    },
    getAppLists() {
      getAppList({
        page: 1,
        limit: 10000,
      }).then((res) => {
        const all = [{ value: '', label: '全部' }];
        const list = res.data.list.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        this.queryList[1].opt = [...all, ...list];
      });
    },
  },
  mounted() {
    this.loadData();
    this.getAppLists();
  },
};
</script>

<style lang="scss" scoped>
.pay-data {
  padding: 30px;
}
</style>
